module.exports = [{
      plugin: require('C:/wamp64/www/github-react-theme/photon/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-104843706-4"},
    },{
      plugin: require('C:/wamp64/www/github-react-theme/photon/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/github-react-theme/photon/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
